import React from 'react';
import { Box, Container, Heading, VStack, Button, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const Home = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const headingColor = useColorModeValue('purple.600', 'purple.300');
  const buttonBgColor = useColorModeValue('purple.500', 'purple.200');
  const buttonTextColor = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} minH="100vh" py={20}>
      <Container maxW="container.md">
        <VStack spacing={8} as={motion.div} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Heading as="h1" size="2xl" textAlign="center" color={headingColor}>
            Welcome to Dejaun's Portfolio
          </Heading>
          <Text fontSize="xl" textAlign="center">
            Explore my projects, research, and more.
          </Text>
          <MotionBox
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              as={RouterLink}
              to="/research"
              size="lg"
              bg={buttonBgColor}
              color={buttonTextColor}
              _hover={{ bg: 'purple.600' }}
            >
              Research Database
            </Button>
          </MotionBox>
          {/* Add more buttons for future sections here */}
        </VStack>
      </Container>
    </Box>
  );
};

export default Home;