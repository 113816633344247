import React, { useEffect, useState } from 'react';
import { Container, Title, Card, Text, Button, SimpleGrid, Box, Badge, TextInput, Modal, Group, Space } from '@mantine/core';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Search, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import './ResearchShowcase.css';

const researchProjects = [
  {
    id: 1,
    title: 'AdversaGuard: Image Attack Analysis and Defense',
    category: 'Tool',
    subcategory: 'Image Processing',
    description: 'A tool for generating, detecting, and defending against adversarial attacks on image classification systems.',
    date: '2023-05-15',
    fullDescription: 'AdversaGuard is a tool designed to generate, detect, and defend against adversarial attacks on image classification systems. This project showcases the implementation of Fast Gradient Sign Method (FGSM) for generating adversarial examples, along with detection and reversion techniques to counteract such attacks.',
    link: 'https://github.com/DejaunG/ImageAttackAnalysis'
  },
  {
    id: 2,
    title: 'Navigating Adversarial Prompts to Secure Large Language Models',
    category: ['Poster', 'Research Paper'],
    subcategory: 'AI Security',
    description: 'Study on vulnerabilities of LLMs like ChatGPT and DALL-E 3 to adversarial prompts.',
    date: '2023-07-22',
    fullDescription: 'This study investigates the vulnerabilities of AI Large Language Models (LLMs) like ChatGPT and DALL-E 3 against sophisticated adversarial prompts. We highlight significant ethical and security risks and propose innovative testing methodologies to enhance AI defenses.',
    link: 'https://github.com/DejaunG/adversarial-prompts-llm-security'
  },
  {
    id: 3,
    title: 'Detection and Mitigation of Multimodal Adversarial Attacks',
    category: 'Poster',
    subcategory: 'AI Defense',
    description: 'Research on detecting and mitigating multimodal adversarial attacks on Large Language Models.',
    date: '2023-09-03',
    fullDescription: 'This study investigates the impact of combining adversarial images with regular images on targeted LLM\'s outputs and proposes strategies to combat these vulnerabilities. Utilizing techniques such as FGSM and PGD, we generated adversarial images and assessed their effects on models like ChatGPT and DALL-E.',
    link: 'https://github.com/DejaunG/multimodal-adversarial-attack-detection'
  },
  {
    id: 4,
    title: 'Robust Testing of AI Language Model Resiliency with Novel Adversarial Prompts',
    category: 'Research Paper',
    subcategory: 'AI Resilience',
    description: 'Evaluation of AI model resilience against adversarial prompts with a new metric.',
    date: '2023-11-11',
    fullDescription: 'This study presents a critical evaluation of the resilience and cybersecurity efficacy of leading AI models, including ChatGPT-4, Bard, Claude, and Microsoft Copilot. It introduces new adversarial tests and the Response Quality Score (RQS), a metric specifically developed to assess the nuances of AI responses.',
    link: 'https://www.mdpi.com/2079-9292/13/5/842'
  },
];

const ProjectCard = ({ project, onClick }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
      layout
    >
      <Card shadow="sm" radius="md" className="card" onClick={onClick}>
        <Group spacing={5} style={{ position: 'absolute', top: '15px', right: '15px' }}>
          {Array.isArray(project.category)
            ? project.category.map((cat, index) => (
                <Badge key={index} className="badge">{cat}</Badge>
              ))
            : <Badge className="badge">{project.category}</Badge>
          }
        </Group>
        <Text className="cardTitle">{project.title}</Text>
        <Text className="cardDescription">{project.description}</Text>
        <Button
          variant="outline"
          className="learnMoreButton"
          component="a"
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </Button>
      </Card>
    </motion.div>
  );
};

const DejaunsProjectDB = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSubcategory, setSelectedSubcategory] = useState('All');
  const [sortOrder, setSortOrder] = useState('newest');
  const [animationKey, setAnimationKey] = useState(0);

  const categories = ['All', ...new Set(researchProjects.flatMap(project =>
    Array.isArray(project.category) ? project.category : [project.category]
  ))];
  const subcategories = ['All', ...new Set(researchProjects.map(project => project.subcategory))];

  const filteredProjects = researchProjects
    .filter(project =>
      (selectedCategory === 'All' ||
       (Array.isArray(project.category) && project.category.includes(selectedCategory)) ||
       project.category === selectedCategory) &&
      (selectedSubcategory === 'All' || project.subcategory === selectedSubcategory) &&
      (project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       project.description.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (sortOrder === 'newest') {
        return new Date(b.date) - new Date(a.date);
      } else {
        return new Date(a.date) - new Date(b.date);
      }
    });

  useEffect(() => {
    setAnimationKey(prevKey => prevKey + 1);
  }, [selectedCategory, selectedSubcategory]);

  return (
    <Container size="lg" className="container">
      <Link to="/" className="homeButton">
        <Home size={24} />
      </Link>

      <Title className="title">
        Dejaun's <span style={{ color: '#6b46c1' }}>Project</span> DB
      </Title>

      <TextInput
        placeholder="Search projects"
        icon={<Search size={24} color="#6b46c1" />}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        className="searchInput"
        size="xl"
      />

      <Space h="xl" />

      <Group position="center" spacing="md" className="categoryGroup">
        {categories.map((category) => (
          <Button
            key={category}
            onClick={() => {
              setSelectedCategory(category);
              setSelectedSubcategory('All');
            }}
            className={`categoryButton ${selectedCategory === category ? 'categoryButtonActive' : ''}`}
          >
            {category}
          </Button>
        ))}
      </Group>

      {selectedCategory !== 'All' && (
        <Group position="center" spacing="md" className="categoryGroup">
          {subcategories.map((subcategory) => (
            <Button
              key={subcategory}
              onClick={() => setSelectedSubcategory(subcategory)}
              className={`categoryButton ${selectedSubcategory === subcategory ? 'categoryButtonActive' : ''}`}
            >
              {subcategory}
            </Button>
          ))}
        </Group>
      )}

      <Space h="xl" />

      <Group position="center" spacing="md" className="categoryGroup">
        <Button
          onClick={() => setSortOrder('newest')}
          className={`categoryButton ${sortOrder === 'newest' ? 'categoryButtonActive' : ''}`}
        >
          Newest First
        </Button>
        <Button
          onClick={() => setSortOrder('oldest')}
          className={`categoryButton ${sortOrder === 'oldest' ? 'categoryButtonActive' : ''}`}
        >
          Oldest First
        </Button>
      </Group>

      <Box style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
        <AnimatePresence key={animationKey}>
          <SimpleGrid
            cols={3}
            spacing={40}
            verticalSpacing={40}
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: 'md', verticalSpacing: 'md' },
              { maxWidth: 755, cols: 1, spacing: 'sm', verticalSpacing: 'sm' },
            ]}
          >
            {filteredProjects.map((project) => (
              <motion.div
                key={project.id}
                layout
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <ProjectCard
                  project={project}
                  onClick={() => setSelectedProject(project)}
                />
              </motion.div>
            ))}
          </SimpleGrid>
        </AnimatePresence>
      </Box>

      <Modal
        opened={selectedProject !== null}
        onClose={() => setSelectedProject(null)}
        title={selectedProject?.title}
        size="lg"
        styles={{
          title: { color: '#6b46c1', fontSize: '1.8rem', fontFamily: "'Raleway', sans-serif", fontWeight: 700 },
          modal: { backgroundColor: '#1a1a1a', borderRadius: '15px' },
          body: { padding: '30px' },
        }}
      >
        {selectedProject && (
          <div>
            <Group spacing={5} mb={20}>
              {Array.isArray(selectedProject.category)
                ? selectedProject.category.map((cat, index) => (
                    <Badge key={index} color="grape" size="xl" style={{ padding: '10px 20px' }}>{cat}</Badge>
                  ))
                : <Badge color="grape" size="xl" style={{ padding: '10px 20px' }}>{selectedProject.category}</Badge>
              }
            </Group>
            <Text style={{ color: '#e0e0e0', marginBottom: '30px', fontFamily: "'Poppins', sans-serif", fontSize: '1.2rem', lineHeight: 1.6 }}>
              {selectedProject.fullDescription}
            </Text>
            <Text style={{ color: '#6b46c1', fontSize: '1.1rem', fontWeight: 600 }}>Date: {selectedProject.date}</Text>
            <Button
              variant="outline"
              className="learnMoreButton"
              component="a"
              href={selectedProject.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Project
            </Button>
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default DejaunsProjectDB;