import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ResearchShowcase from './components/ResearchShowcase';

function App() {
  return (
    <MantineProvider>
      <ChakraProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/research" element={<ResearchShowcase />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </MantineProvider>
  );
}

export default App;